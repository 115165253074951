.carousel-root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel.carousel-slider {
  display: flex;
  flex-direction: column-reverse;
}

.carousel .slider-wrapper {
  max-width: 100%;
  /* height: auto !important; */
}
